import { Bone, Skeleton } from 'components/SkeletonKit'
import { Box } from 'design-system/box'

export const MenuItemPlaceholder = () => (
  <Box flex alignItems="center" css={{ gap: '$l' }}>
    <Box css={{ flex: 1 }}>
      <Bone variant="body1" css={{ width: '60%' }} />
      <Bone variant="body3" />
      <Bone variant="body3" />
      <Bone variant="body1" css={{ width: '30%' }} />
    </Box>
    <Bone css={{ width: 92, height: 92 }} />
  </Box>
)

export const MenuCategoryPlaceholder = () => (
  <Box flex css={{ gap: '$s', flexDirection: 'column' }}>
    <Bone
      variant="heading3"
      css={{ borderRadius: '$full', marginBottom: '$s' }}
    />
    <MenuItemPlaceholder />
    <MenuItemPlaceholder />
    <MenuItemPlaceholder />
  </Box>
)

export const StoreMenuPlaceholder = () => (
  <Skeleton
    data-testid="store-menu-placeholder"
    css={{ padding: '$l $s', gap: '$s' }}
  >
    <MenuCategoryPlaceholder />
    <MenuCategoryPlaceholder />
  </Skeleton>
)
