import { CSS } from '@stitches/react'
import { isToday, format as formatDate } from 'date-fns'
import React from 'react'

/* Components */
import * as Icon from 'components/DesignSystemIcons'
import ImageWithAspectRatio from 'components/ImageWithAspectRatio'

/* Config */
import { styled } from 'styles/stitches.config'

/* Design system */
import { Span } from 'design-system/spans'

/* Utils */
import { formatMoney } from 'utils/money'
import { pluralize } from 'utils/stringUtils'
import { getOrderStatusColor, mapOrderStatus } from 'utils/order'
import { isNil } from 'lodash'

const Article = styled('article', {
  borderBottom: '1px solid $grey200',
  lineHeight: '1.6',
  display: 'flex',
  padding: '$m 0',
  margin: '0 $s'
})

const ImageWrapper = styled('div', {
  marginRight: '$s',
  marginBottom: '$s'
})

const Container = styled('div', {
  flex: 1,
  position: 'relative'
})

const Header = styled('header', {
  fontSize: '$body3',
  lineHeight: '1em',

  '& span': {
    verticalAlign: 'top'
  },

  [`& ${Span}`]: {
    lineHeight: '0.75rem'
  }
})

const Main = styled('main', {
  color: '$grey1000',
  fontSize: '$body1',
  fontWeight: '$bold',
  lineHeight: '$body1',
  margin: '$xxxs 0',

  variants: {
    big: {
      true: {
        fontSize: '$heading4',
        lineHeight: '$heading4',
        fontWeight: '$extraBold'
      }
    }
  }
})

const Footer = styled('footer', {
  display: 'flex',
  fontSize: '$body2',
  justifyContent: 'space-between',
  lineHeight: '1em',

  '& > *:first-child': {
    color: '$grey800'
  },

  '& > *:last-child': {
    color: '$grey700',
    fontWeight: '$bold'
  }
})

const DetailIconWrapper = styled('div', {
  color: '$grey500',
  position: 'absolute',
  right: 0,
  top: 0
})

const OrderDate = styled('span', {
  fontSize: '$body3',
  color: '$grey800'
})

type OrderCardDetailsProps = {
  storeName: string
  storePhoto: string
  orderIssuedAt: string
  inTotal?: number
  numberOfItems?: number
  pointsEarned?: number
  orderStatus?: string
  css?: CSS
  imageSize?: number
  hasImage?: boolean
  bigHeader?: boolean
}

const OrderCardDetails = ({
  css,
  storeName,
  storePhoto,
  orderIssuedAt,
  orderStatus,
  numberOfItems,
  inTotal,
  pointsEarned,
  imageSize = 25,
  hasImage = true,
  bigHeader
}: OrderCardDetailsProps) => {
  const orderIssueDate = new Date(orderIssuedAt)

  const showFooter =
    !isNil(pointsEarned) && !isNil(numberOfItems) && !isNil(inTotal)

  return (
    <Article css={css}>
      {!!hasImage && (
        <ImageWrapper css={{ width: `${imageSize}%`, maxWidth: '52px' }}>
          <ImageWithAspectRatio
            alt={storeName}
            aspectRatio={100}
            css={{
              borderRadius: '50%'
            }}
            src={storePhoto || '/images/coffee-shop-default.jpg'}
          />
        </ImageWrapper>
      )}
      <Container>
        <Header>
          <OrderDate>
            {formatDate(
              orderIssueDate,
              isToday(orderIssueDate) ? "'Today, 'h:mm a" : 'MMMM dd, h:mm a'
            )}
          </OrderDate>
          {!!orderStatus && (
            <Span tag color={getOrderStatusColor(orderStatus)}>
              {mapOrderStatus(orderStatus)}
            </Span>
          )}
        </Header>

        <Main big={bigHeader}>{storeName}</Main>

        {showFooter && (
          <Footer>
            <span>
              {pluralize(numberOfItems, 'item_items')} • {formatMoney(inTotal)}
            </span>
            <span>{pointsEarned} pts earned</span>
          </Footer>
        )}

        <DetailIconWrapper>
          <Icon.ChevronRight />
        </DetailIconWrapper>
      </Container>
    </Article>
  )
}

export default OrderCardDetails
