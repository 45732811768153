import { styled } from 'styles/stitches.config'
import { Link, LinkProps } from 'react-scroll'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Bone, Skeleton } from 'components/SkeletonKit'
import { CSS } from '@stitches/react'

export const MenuTabsList = styled('ol', {
  fontSize: 0,
  width: '100%',
  top: '$headerHeight',
  position: 'sticky',
  zIndex: 99,
  backgroundColor: '$grey50',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  padding: '$xs $xxs',
})

const Tab = styled('li', {
  backgroundColor: 'transparent',
  position: 'relative',
  color: '$grey600',
  display: 'inline-block',
  fontSize: '$body1',
  fontWeight: '$normal',
  lineHeight: '$body1',
  padding: '$xs $s $s',
  whiteSpace: 'nowrap',
  width: '100%',
  textAlign: 'center',
  borderBottom: '1px solid $grey150',
})

const TabLink = styled(Link, {
  flexGrow: 1,

  [`&.active > ${Tab}`]: {
    color: '$black',
    fontWeight: '$bold',

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '3px',
      borderRadius: '$full',
      backgroundColor: '$black',
    },
  },
})

export const MenuTab = ({
  children,
  onSetActive,
  active,
  ...props
}: Omit<LinkProps, 'ref'> & { active: boolean }) => {
  const tabRef = useRef<HTMLLIElement>(null)
  const className = active ? 'active' : ''

  const handleSetActive: LinkProps['onSetActive'] = useCallback(
    (to, element) => {
      // istanbul ignore else
      if (tabRef.current && tabRef.current.offsetParent) {
        const parent = tabRef.current.offsetParent
        const parentWidth = parent.clientWidth
        const elementWidth = tabRef.current.clientWidth
        const offsetLeft = tabRef.current.offsetLeft

        // Calculate the center position
        const scrollPosition = offsetLeft - parentWidth / 2 + elementWidth / 2

        parent.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        })
      }

      onSetActive?.(to, element)
    },
    [onSetActive]
  )

  return (
    <TabLink className={className} {...props} onSetActive={handleSetActive}>
      <Tab ref={tabRef}>{children}</Tab>
    </TabLink>
  )
}

export const MenuTabs = <T extends string>({
  tabs,
  onSetActive,
  defaultTab = tabs[0],
  isLoading = false,
  css,
  ...props
}: Omit<LinkProps, 'ref' | 'to' | 'spy'> & {
  tabs: T[]
  defaultTab?: T
  isLoading?: boolean
  css?: CSS
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab)

  useEffect(() => {
    const updateActiveTab = () => {
      if (window.scrollY === 0) {
        setActiveTab(defaultTab)
      }
    }

    updateActiveTab()

    window.addEventListener('scrollend', updateActiveTab)

    return () => {
      window.removeEventListener('scrollend', updateActiveTab)
    }
  }, [defaultTab])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleSetActive: LinkProps['onSetActive'] = useCallback(
    (to, element) => {
      setActiveTab(to)
      onSetActive?.(to, element)
    },
    [onSetActive]
  )

  return (
    <MenuTabsList css={css}>
      {isLoading ? (
        <TabsPlaceholder />
      ) : (
        tabs.map((tab) => (
          <MenuTab
            key={tab}
            to={tab}
            active={activeTab === tab}
            spy
            {...props}
            onSetActive={handleSetActive}
          >
            {tab}
          </MenuTab>
        ))
      )}
    </MenuTabsList>
  )
}

const TabsPlaceholder = () => (
  <Skeleton
    data-testid="tabs-placeholder"
    flex
    css={{ flexDirection: 'row', gap: '$m', padding: '$0' }}
  >
    <Bone variant="body1" css={{ flex: 1, margin: 0 }} />
    <Bone variant="body1" css={{ flex: 1, margin: 0 }} />
    <Bone variant="body1" css={{ flex: 1, margin: 0 }} />
    <Bone variant="body1" css={{ flex: 1, margin: 0 }} />
  </Skeleton>
)
