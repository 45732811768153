import { api, DefaultApi } from 'config/apiClient'
import { useMemo } from 'react'
import {
  default as originalUseSWR,
  SWRConfiguration,
  SWRResponse,
  unstable_serialize,
} from 'swr'
import {
  MutationFetcher,
  default as originalUseSWRMutation,
  SWRMutationConfiguration,
} from 'swr/mutation'
import { ApiStatus, ArgsMap, AsyncReturnType } from 'types/api'

type FetcherKey<T extends keyof ArgsMap> = [T, ArgsMap[T]]

const apiFetcher = async <T extends keyof ArgsMap>(
  method: T,
  args: ArgsMap[T]
) => {
  return api(method, args)
}

export const useSWR = <T extends keyof ArgsMap>(
  method: T,
  args: ArgsMap[T],
  swrOptions?: SWRConfiguration<AsyncReturnType<DefaultApi[T]> & ApiStatus> & {
    shouldFetch?: boolean
  }
): SWRResponse<AsyncReturnType<DefaultApi[T]> & ApiStatus> => {
  const { shouldFetch = true, ...options } = swrOptions ?? {}

  const serializedKey = unstable_serialize([method, args])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const key = useMemo(() => [method, args], [serializedKey]) as FetcherKey<T>

  const swr = originalUseSWR<AsyncReturnType<DefaultApi[T]> & ApiStatus>(
    shouldFetch ? key : null,
    (key: FetcherKey<T>) => apiFetcher(...key),
    options
  )

  return swr
}

export const useSWRMutation = <T extends keyof ArgsMap>(
  method: T,
  options?: SWRMutationConfiguration<
    AsyncReturnType<DefaultApi[T]> & ApiStatus,
    unknown,
    string,
    ArgsMap[T]
  >
) => {
  const mutationFetcher: MutationFetcher<
    AsyncReturnType<DefaultApi[T]> & ApiStatus,
    string,
    ArgsMap[T]
  > = (key, { arg }) => apiFetcher(key as T, arg)

  const swrMutation = originalUseSWRMutation(method, mutationFetcher, options)

  return swrMutation
}
