import { Carousel, CarouselOptions } from 'components/Carousel'
import GenericContentWidget from 'components/GenericContentWidget'
import MyJoeOrderCard from 'components/pages/account/orders/MyJoeOrderCard'
import { useLoyaltyData } from 'hooks/useLoyaltyData'
import { isEmpty } from 'lodash'
import Router from 'next/router'
import { useEffect, useMemo } from 'react'
import { OrderMyJoe, OrderMyJoeItem } from 'types/api'
import { RecentOrderCard } from './components'

const orderCardCustomStyling = {
  borderBottom: 'none',
  paddingBottom: '0',
}

type Props = {
  storeId?: string
  onClickUsualItemMark: (item: OrderMyJoeItem, storeId: string) => void
  onFetchRecentOrders?: (data: OrderMyJoe[]) => void
}

export const RecentOrders = ({
  storeId,
  onClickUsualItemMark,
  onFetchRecentOrders,
}: Props) => {
  const {
    loyaltyData: { recentOrders, isLoadingMyJoe: isLoading },
  } = useLoyaltyData('recentOrders', storeId)

  // TODO: This should come filtered from the API
  const recentOrdersByStore = useMemo(
    () => recentOrders.filter((order) => order.storeId === storeId),
    [recentOrders, storeId]
  )

  const carouselOptions: CarouselOptions = {
    align: 'prev',
    panelsPerView: 1,
    pagination: true,
    adaptive: true,
    horizontal: true,
  } as unknown as CarouselOptions

  const handleViewMore = () => {
    Router.push({
      pathname: 'my-joe/recent-orders',
      query: {
        storeId,
      },
    })
  }

  const recentOrdersComponent = useMemo(() => {
    if (storeId) {
      return recentOrdersByStore.map((order) => (
        <RecentOrderCard
          key={order.orderId}
          order={order}
          onClickUsualItemMark={onClickUsualItemMark}
        />
      ))
    }

    return recentOrders.map((order) => (
      <MyJoeOrderCard
        css={orderCardCustomStyling}
        key={order.orderId}
        order={order}
        onClickUsualItemMark={onClickUsualItemMark}
      />
    ))
  }, [storeId, recentOrders, recentOrdersByStore, onClickUsualItemMark])

  useEffect(() => {
    const orders = storeId ? recentOrdersByStore : recentOrders

    onFetchRecentOrders?.(orders)
  }, [recentOrders, onFetchRecentOrders, recentOrdersByStore, storeId])

  if ((storeId && isEmpty(recentOrdersByStore)) || isEmpty(recentOrders)) {
    return null
  }

  return (
    <GenericContentWidget
      isLoading={isLoading}
      title="Recent Orders"
      action={handleViewMore}
      css={{ margin: '0 -$s' }}
    >
      <Carousel
        options={carouselOptions}
        customCss={{ zIndex: 0, position: 'relative' }}
      >
        {recentOrdersComponent}
      </Carousel>
    </GenericContentWidget>
  )
}
