import { Carousel, CarouselOptions } from 'components/Carousel'
import GenericContentWidget from 'components/GenericContentWidget'
import { FavoriteItem } from 'components/pages/my-joe/FavoriteWidget/components'
import { api } from 'config/apiClient'
import { useOrderUsualItem } from 'hooks/useOrderUsualItem'
import { isEmpty, omit } from 'lodash'
import Router from 'next/router'
import { useEffect, useState } from 'react'
import { FavoriteStore, OrderMyJoeItem, UsualItem } from 'types/api'

type Props = {
  storeId: string
  onClickUsualItemMark: (item: OrderMyJoeItem, storeId: string) => void
  onFetchFavorites?: (data: UsualItem[]) => void
}

export const YourFavorites = ({ storeId, onClickUsualItemMark, onFetchFavorites }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [favoriteItems, setFavoriteItems] = useState<UsualItem[]>([])
  const [store, setStore] = useState<FavoriteStore>({} as FavoriteStore)
  const [hasFetched, setHasFetched] = useState(false)

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!storeId || hasFetched) return

      setIsLoading(true)

      try {
        const data = await api('getStoreUsualItems', { storeId })

        setFavoriteItems(data.data.items)
        onFetchFavorites?.(data.data.items)
        setStore(data.data.store)
        setHasFetched(true)
      } catch (error) {
        console.error('Error fetching favorites:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchFavorites()
  }, [storeId, hasFetched, onFetchFavorites])

  useEffect(() => {
    return () => {
      setFavoriteItems([])
      setStore({} as FavoriteStore)
      setHasFetched(false)
    }
  }, [])

  const carouselOptions: CarouselOptions = {
    align: 'prev',
    panelsPerView: 2.5,
  } as unknown as CarouselOptions

  const handleViewMore = () => {
    Router.push({
      pathname: 'explore/stores/[storeId]/favorite-items',
      query: {
        storeId
      }
    })
  }

  const handleOnClickUsualItemMark = (item: UsualItem) =>
    onClickUsualItemMark({
    ...omit(item, [
      'choices'
    ]),
    modifiers: item.choices,
  }, storeId)

  const { loading, orderUsualItem } = useOrderUsualItem(store)

  if (isEmpty(favoriteItems) && !isLoading) return null

  return (
    <GenericContentWidget isLoading={isLoading} title="Your Favorites" action={handleViewMore}>
      <Carousel options={carouselOptions} customCss={{ zIndex: 0, position: 'relative', marginRight: '-$s' }}>
        {favoriteItems?.map((item) => (
          <FavoriteItem
            key={item.id}
            item={item}
            storeId={storeId}
            loading={loading}
            orderUsualItem={orderUsualItem}
            handleOnClickUsualItemMark={handleOnClickUsualItemMark}
          />
        ))}
      </Carousel>
    </GenericContentWidget>
  )
}
