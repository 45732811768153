import { Offer } from 'types/api'
import { DayOfWeek } from 'types/date'

const ValueOffers = [
  'monday-mania',
  'double-points',
  'fuel-up-friday',
  'referral',
]

export const isValueOffer = (offerId: string) => {
  return ValueOffers.some((offer) => offer === offerId)
}

export const filterOfferByDay = (offers: Offer[], day: DayOfWeek) => {
  switch (day) {
    case 'monday':
      return offers.filter((offer) => offer.id === 'monday-mania')
    case 'wednesday':
      return offers.filter((offer) => offer.id === 'double-points')
    case 'friday':
      return offers.filter((offer) => offer.id === 'fuel-up-friday')
    case 'tuesday':
    case 'thursday':
    case 'saturday':
    case 'sunday':
      return offers.filter((offer) => offer.id === 'referral')
    default:
      return offers
  }
}
