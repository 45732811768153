import Link from 'next/link'
import ImageWithAspectRatio from 'components/ImageWithAspectRatio'
import { formatMoney } from 'utils/money'
import { StoreMenuItemProps } from './StoreMenu.types'
import {
  Item,
  ItemDescription,
  ItemImage,
  ItemInfo,
  ItemName,
  ItemPrice,
  NewTag,
  PopularTag,
} from './StoreMenu.styles'

const StoreMenuItem = ({ item, storeId }: StoreMenuItemProps) => {
  return (
    <Link
      href={{
        pathname: '/explore/stores/[storeId]/[itemId]',
        query: {
          storeId,
          itemId: item.itemId,
        },
      }}
      key={item.itemId}
      passHref
    >
      <Item>
        <ItemInfo>
          <ItemName>{item.name}</ItemName>
          {item.status === 'new' && <NewTag>New Item!</NewTag>}
          {item.status === 'popular' && <PopularTag>Most Popular</PopularTag>}
          {item.description && (
            <ItemDescription>{item.description}</ItemDescription>
          )}
          <ItemPrice>{formatMoney(item.price)}</ItemPrice>
        </ItemInfo>

        {item.photo && (
          <ItemImage>
            <ImageWithAspectRatio
              aspectRatio={100}
              alt={item.name}
              src={item.photo}
            />
          </ItemImage>
        )}
      </Item>
    </Link>
  )
}

export default StoreMenuItem
