import { MouseEventHandler, useCallback, useMemo } from 'react'
import { styled } from 'styles/stitches.config'
import { OrderMyJoeItem } from 'types/api'
import ImageFadeIn from 'components/ImageFadeIn'
import { Button } from 'design-system'
import Big from 'big.js'
import { formatMoney } from 'utils/money'
import UsualItemButton from 'components/UsualItemButton'

const Container = styled('div', {
  display: 'flex',
  gap: '$s'
})

const HeaderWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
  flex: 0,
})

const ImageWrapper = styled('div', {
  width: '72px',
})

const Info = styled('div', {
  flex: 1,
  color: '$grey800',
  fontSize: '$body3',
  lineHeight: '$body3'
})

const Name = styled('div', {
  fontSize: '$body1',
  lineHeight: '$body1',
  fontWeight: '$bold'
})

const OptionSection = styled('div', {
  marginTop: '$xxxs',
  marginLeft: '$s'
})

const Notes = styled('div', {
  marginTop: '$xxxs',
})

const Optional = styled('div', {
  flexGrow: 0,

  display: 'flex',
  alignItems: 'end',
  flexDirection: 'column',
  justifyContent: 'space-between'
})

const AddItemButtonStyling = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$small',
  padding: '$xs $xxl',
  height: '40px',
  width: 'fit-content'
}

const PriceTag = styled('div', {
  color: '$grey800',
  fontSize: '$body1',
  lineHeight: '$body1',
  fontWeight: '$bold'
})

interface Props {
  item: OrderMyJoeItem
  onAdd?: () => void
  onClickUsualItemMark?: () => void
  loading?: boolean
}

export const OrderCardItem = ({ item, onAdd, loading, onClickUsualItemMark: onUnmarkedUsualItem }: Props) => {
  const itemOptions = useMemo(() => {
    const options: {
      id: string
      name: string
      price?: string | number
    }[] = [...item.modifiers]

    // istanbul ignore else
    if (item.size) {
      options.unshift(item.size)
    }

    return options
  }, [item])

  const price = useMemo(() => itemOptions.reduce((price, option) => price.add(Big(option.price ?? 0))
    , Big(0)), [itemOptions])

  const onClickUsualItemMark: MouseEventHandler<HTMLSpanElement> = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()

    // istanbul ignore next
    if (!onUnmarkedUsualItem) return

    onUnmarkedUsualItem()
  }, [onUnmarkedUsualItem])

  const isUsualItem = useMemo(() => !!item.usualItemId, [item.usualItemId])

  return (
    <Container data-testid={item.id}>
      <HeaderWrapper>
        {!!onClickUsualItemMark && (
          <UsualItemButton onClick={onClickUsualItemMark} isUsualItem={isUsualItem} id={item.id} />
        )}
        <ImageWrapper>
          <ImageFadeIn alt={item.name} src={item.photo} aspectRatio={100} css={{ borderRadius: '$xs' }} />
        </ImageWrapper>
      </HeaderWrapper>
      <Info>
        <Name>{item.name}</Name>
        {!!itemOptions && (
          <OptionSection>
            {itemOptions.map((option) => (
              <li key={option.id}>{option.name}</li>
            ))}
          </OptionSection>
        )}
        {!!item.note && (
          <Notes><strong>Note:</strong>{` "${item.note}"`}</Notes>
        )}
      </Info>
      {!!onAdd && (
        <Optional>
          <PriceTag>{formatMoney(price.div(100).toNumber())}</PriceTag>
          <Button
            css={AddItemButtonStyling}
            cta
            disabled={loading}
            onClick={onAdd}
            size="small"
            variant="secondary"
            data-testid="add-item-button"
          >Add</Button>
        </Optional>
      )}
    </Container>
  )
}
