import { some } from 'lodash'
import { useSWR } from './useSWR'

export const useDeals = (companyId: string) => {
  const { data, ...rest } = useSWR('getCompanyGiftCardDeals', { companyId })

  const { deals, totalPurchased, totalRedeemed } = data?.data ?? {}
  const hasDeals = some(deals, (deal) => deal.save > 0)

  return { hasDeals, deals, totalPurchased, totalRedeemed, ...rest }
}
