import Big from 'big.js'
import { api } from 'config/apiClient'
import { set } from 'lodash'
import { useEffect, useState } from 'react'
import { FavoriteStore, Offer, OrderMyJoe } from 'types/api'
import { StoreTopOrderedItem, StoreUpsellItem } from 'types/api/store'
import { DayOfWeek } from 'types/date'
import { filterOfferByDay } from 'utils/offer'
import { populateUsualItemIdForRecentOrders } from 'utils/order'

export type LoyaltyData = {
  featuredItems: StoreUpsellItem[]
  isLoadingFeaturedItems: boolean
  storeTop10: StoreTopOrderedItem[]
  isLoadingStoreTop10: boolean
  joeOffers: Offer[]
  isLoadingJoeOffers: boolean
  favoritesStores: FavoriteStore[]
  recentOrders: OrderMyJoe[]
  isLoadingMyJoe: boolean
}

const initialState: LoyaltyData = {
  featuredItems: [],
  isLoadingFeaturedItems: false,
  storeTop10: [],
  isLoadingStoreTop10: false,
  joeOffers: [],
  isLoadingJoeOffers: false,
  favoritesStores: [],
  recentOrders: [],
  isLoadingMyJoe: false,
}

export const useLoyaltyData = (
  action: 'storeTop10' | 'storeFeatured' | 'myJoe' | 'favoriteStores' | 'recentOrders' | 'joeOffers',
  selectedStore?: string | null
): { loyaltyData: LoyaltyData } => {
  const [loyaltyData, setLoyaltyData] = useState<LoyaltyData>(initialState)

  useEffect(() => {
    const updateLoyaltyData = async (): Promise<void> => {
      try {
        switch (action) {
          case 'joeOffers': {
            setLoyaltyData((prev) => ({ ...prev, isLoadingJoeOffers: true }))

            const overrideDay = new Date().toLocaleString('en-US', { weekday: 'long' }).toLowerCase() as DayOfWeek
            const { data: offers } = await api('getJoeOffers', { overrideDay })

            const joeOffers = filterOfferByDay(offers, overrideDay)

            setLoyaltyData((prev) => ({
              ...prev,
              joeOffers,
              isLoadingJoeOffers: false,
            }))

            break
          }

          case 'storeTop10':
            if (!selectedStore) return

            setLoyaltyData((prev) => ({
              ...prev,
              isLoadingStoreTop10: true,
            }))

            const { data: storeTop10Items } = await api('getStoreTopOrderedItems', { storeId: selectedStore })

            setLoyaltyData((prev) => ({
              ...prev,
              storeTop10: storeTop10Items.map((item) => ({
                ...item,
                price: Big(item.totalCurrencyamount).div(item.totalCurrencyfraction).toNumber(),
              })),
              isLoadingStoreTop10: false,
            }))

            break
          case 'storeFeatured': {
            if (!selectedStore) return

            setLoyaltyData((prev) => ({
              ...prev,
              isLoadingFeaturedItems: true,
            }))

            const { data } = await api('getCartOptionByStoreId', {
              storeId: selectedStore,
            })

            setLoyaltyData((prev) => ({
              ...prev,
              featuredItems: data.featuredItems,
              isLoadingFeaturedItems: false,
            }))

            break
          }

          case 'myJoe':
          case 'recentOrders':
          case 'favoriteStores': {
            setLoyaltyData((prev) => ({ ...prev, isLoadingMyJoe: true }))

            const { data: myJoeData } = await api('userMyJoe', undefined)
            const { favorite_stores: favoritesStores, recentOrders } = myJoeData

            setLoyaltyData((prev) =>({
              ...prev,
              favoritesStores: favoritesStores ?? [],
              recentOrders: populateUsualItemIdForRecentOrders(
                recentOrders.filter((order) => order !== null),
                favoritesStores ?? []
              ),
              isLoadingMyJoe: false,
            }))

            break
          }
        }
      } catch (error) {
        setLoyaltyData((prev) => set(prev, `isLoading${action[0].toUpperCase()}${action.slice(1)}`, false))
        console.error('Error updating loyalty data:', error)
      }
    }

    updateLoyaltyData()
  }, [action, selectedStore])

  return { loyaltyData }
}
