import { Carousel, CarouselOptions } from 'components/Carousel'
import GenericContentWidget from 'components/GenericContentWidget'
import { useLoyaltyData } from 'hooks/useLoyaltyData'
import { isEmpty } from 'lodash'
import Router from 'next/router'
import { ItemCard } from '../FeaturedItems/components'
import { StoreTopOrderedItem } from 'types/api/store'
import { useEffect } from 'react'

type Props = {
  storeId: string
  onFetchRecommendedItems?: (data: StoreTopOrderedItem[]) => void
}

export const RecommendedItems = ({ storeId, onFetchRecommendedItems }: Props) => {
  const { loyaltyData: { storeTop10, isLoadingStoreTop10: isLoading } } = useLoyaltyData('storeTop10', storeId)

  useEffect(() => {
      onFetchRecommendedItems?.(storeTop10)
  }, [storeTop10, onFetchRecommendedItems])

  if (!storeId || (isEmpty(storeTop10) && !isLoading)) return null

  const carouselOptions: CarouselOptions = {
    align: 'prev',
    panelsPerView: 1.8,
  } as unknown as CarouselOptions

  const handleViewMore = () => {
    Router.push({
      pathname: 'explore/stores/[storeId]/recommended-items',
      query: {
        storeId
      }
    })
  }


  return (
    <GenericContentWidget isLoading={isLoading} title="Recommended" action={handleViewMore}>
      <Carousel options={carouselOptions} customCss={{ zIndex: 0, position: 'relative', marginRight: '-$s' }}>
        {storeTop10?.map((item) => (
          <ItemCard key={item.name} item={item} storeId={storeId} css={{ paddingRight: '$s' }} />
        ))}
      </Carousel>
    </GenericContentWidget>
  )
}
