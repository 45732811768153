import { useRef, useState } from 'react'

import { Button } from 'design-system/buttons'
import { Modal, useModal } from 'components/ModalKit'
import { StandardModal } from 'components/Modals'

import { api } from 'config/apiClient'
import { cartState } from 'config/states'
import { useRecoilState } from 'recoil'
import { useRouter } from 'next/router'
import { v4 as uuidV4 } from 'uuid'

import type { CartData, Item } from 'types/api/cart'
import { rebuildCart } from 'utils/cart'
import { useCheckPushNotificationPermission } from './useCheckPushNotificationPermission'
import useNotification from './useNotification'
import { getCartItemHash } from 'utils/order'
import { filter } from 'lodash'

const useOrderAgain = (orderId: string, orderUsualItemHashmap?: Map<string, string>) => {

  const router = useRouter()
  const [cart, setCart] = useRecoilState(cartState)
  const { openModal, closeModal } = useModal()
  const [loading, setLoading] = useState(false)
  const modalId = useRef('')
  const { checkPushNotificationPermission } = useCheckPushNotificationPermission()
  const { notifyError } = useNotification()

  const addItemsToCart = (items: Item[]) => {
    setCart({
      ...cart,
      items: cart.items.concat(items).map((item) => ({ ...item, id: uuidV4() }))
    })

    checkPushNotificationPermission()
  }

  const replaceCartData = (data: CartData) => {
    setCart(data)
    checkPushNotificationPermission()
  }

  const goToCartPage = () => router.push({
    pathname: '/cart',
  })

  const onConfirmDiscardCurrentCart = (data: CartData) => () => {
    replaceCartData(data)
    closeModal(modalId.current)
    goToCartPage()
  }

  const handleOnClickOrderAgain = async (orderItemId?: string) => {
    setLoading(true)

    const res = await api('getOrderRepeat', {
      orderId,
    })

    if (!res.success) {
      return notifyError('Cannot order again due to some items are unavailable. Please order manually.', { autoClose: 7000 })
    }

    const rebuiltCart = rebuildCart(res.data)
    const itemsToUse = orderItemId ? filter(rebuiltCart.items, (item) => item.itemOriginalId === orderItemId) : rebuiltCart.items

    // istanbul ignore next
    const repeatCart: CartData = {
      ...rebuiltCart,
      items: orderUsualItemHashmap?.size ? itemsToUse.map((item) => {
        const usualItemId = orderUsualItemHashmap.get(getCartItemHash(item, rebuiltCart.storeId))
        return {
          ...item,
          usualItemId
        }
      }) : itemsToUse
    }

    if (cart.storeId === res.data.store.id) {
      addItemsToCart(repeatCart.items)
      return goToCartPage()
    }

    if (!cart.storeId) {
      replaceCartData(repeatCart)
      return goToCartPage()
    }

    setLoading(false)
    return modalId.current = openModal(
      <StandardModal>
        <Modal.Header>Existing cart detected!</Modal.Header>
        <Modal.Main>
          Heads up! By clicking on continue, you will discard your current cart from {cart.storeName}
        </Modal.Main>
        <Modal.Footer>
          <Button onClick={onConfirmDiscardCurrentCart(repeatCart)} type="button" cta variant="primary" size="large">Continue</Button>
        </Modal.Footer>
      </StandardModal>
    )
  }

  return {
    loading,
    handleOnClickOrderAgain
  }
}

export default useOrderAgain
