import { styled } from 'styles/stitches.config'
import * as Icon from 'components/DesignSystemIcons'

export const CardContainer = styled('div', {
  marginRight: '$s',
  marginBottom: '$l',
  boxShadow: '$generalDrop',
  borderRadius: '$l',
  variants: {
    fullWidth: {
      true: {
        minWidth: '375px',
      },
    },
  },

  '@media (min-width: 768px)': {
    width: 'calc(100% - 12px)',
  },
})

export const CardActionsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '$s',
  width: '100%',
  padding: '$xs $s',
})

export const Title = styled('span', {
  fontSize: '$body2',
  fontWeight: '$normal',
  lineHeight: '$body2',
})

export const SectionTitle = styled('p', {
  fontSize: '$heading3',
  fontWeight: '$bold',
  lineHeight: '$heading3',
  margin: '$l $s',
  paddingLeft: '$s',
})

export const Badge = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$xxs $xs',
  backgroundColor: '$red500',
  color: '$white',
  borderRadius: '$l',
  position: 'absolute',
  top: '-15px',
  right: '-5px',
  fontSize: '10px',
  border: '2px solid $white',
  boxShadow: '$generalDrop',

  svg: {
    marginRight: '2px',
  },
})

export const DealBadge = () => (
  <Badge>
    <Icon.Discount size={10} />
    Deal
  </Badge>
)
