// istanbul ignore file
import Big from 'big.js'
import { Cart, Heart, Plus } from 'components/DesignSystemIcons'
import { format as formatDate } from 'date-fns'
import useOrderAgain from 'hooks/useOrderAgain'
import { useMemo } from 'react'
import { styled } from 'styles/stitches.config'
import { OrderMyJoe, OrderMyJoeItem } from 'types/api'
import { formatMoney } from 'utils/money'
import { getUsualItemHashmap } from 'utils/order'
import { calculateUsualItemPrice, useToggleFavorite } from 'utils/usualItems'

const RecentOrder = styled('div', {
  padding: '$m $s',
  borderRadius: '$s',
  backgroundColor: '$white',
  margin: '$xxl $s',
  boxShadow: '$feed',
})

const IssuedAt = styled('p', {
  fontSize: '$body2',
  color: '$grey800',
  marginBottom: '$l',
})

const ItemsList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s',
})

const OrderCardItem = styled('div', {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  backgroundColor: '$white',
  position: 'relative',

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '$s',
  },
})

const Icon = styled('div', {
  position: 'absolute',
  width: 24,
  height: 24,
  color: '$red500',
  backgroundColor: '$white',
  borderRadius: '50%',
  top: 3,
  left: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    active: {
      true: {
        '& svg': {
          color: '$red500',
          '& path': {
            stroke: '$red500',
          },
        },
      },
    }
  },

  '& svg': {
    width: 16,
    height: 16,
    color: '$white',
    '& path': {
      stroke: '$grey600',
    },
  },
})

const ItemPhoto = styled('div', {
  position: 'relative',
  width: 64,
  height: 64,
  borderRadius: '$s',
  overflow: 'hidden',
  cursor: 'pointer',
  color: '$red500',
  marginRight: '$s',

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
})

const ItemName = styled('p', {
  fontSize: '$body2',
  color: '$grey1000',
})

const Modifiers = styled('p', {
  fontSize: '$body3',
  color: '$grey700',
})

const AddToCartBtn = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: '50%',
  background: '$black',
  color: '$white',
  cursor: 'pointer',
  position: 'absolute',
  bottom: 5,
  right: 5,
  variants: {
    disabled: {
      true: {
        backgroundColor: '$grey300',
        color: '$grey700',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      }
    }
  },
})

const Divider = styled('div', {
  height: 1,
  backgroundColor: '$grey100',
  marginTop: '$l',
  marginBottom: '$s',
})

const Button = styled('div', {
  color: '$grey1000',
  fontWeight: '$bold',
  lineHeight: '$body2',
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
  cursor: 'pointer',

  variants: {
    disabled: {
      true: {
        color: '$grey700',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      }
    }
  }
})

const Action = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '$grey800',
  fontSize: '$body2',
})

const OrderItem: React.FC<{
  item: OrderMyJoeItem
  isFavorite: boolean
  storeId: string
  orderId: string
  handleItemClick: (item: OrderMyJoeItem) => void
}> = ({ item, isFavorite, storeId, orderId, handleItemClick }) => {
  const { name, photo, modifiers, size } = item
  const price = calculateUsualItemPrice(item)
  const sizeAndModifiers = `${size.name}${modifiers.map(({ name }) => `, ${name}`)}`

  const usualItemHashmap = useMemo(() =>
    getUsualItemHashmap([item], storeId)
    , [item, storeId])

  const { handleOnClickOrderAgain: orderAgain, loading } = useOrderAgain(
    orderId,
    usualItemHashmap
  )

  return (
    <OrderCardItem>
      <ItemPhoto onClick={() => handleItemClick(item)}>
        <Icon active={isFavorite}><Heart /></Icon>
        <img src={photo} alt="" />
      </ItemPhoto>
      <div>
        <ItemName>{name}</ItemName>
        <Modifiers>{sizeAndModifiers}</Modifiers>
        <div>{formatMoney(price)}</div>
      </div>
      <AddToCartBtn onClick={() => orderAgain(item.id)} disabled={loading}>
        <Plus size={12} />
      </AddToCartBtn>
    </OrderCardItem>
  )
}

type Props = {
  order: OrderMyJoe
  type?: 'full' | 'compact'
  onClickUsualItemMark: (item: OrderMyJoeItem, storeId: string) => void
}

export const RecentOrderCard: React.FC<Props> = ({ order, type = 'compact', onClickUsualItemMark }) => {
  const { ts, storeId, items, goodsTotalCurrencyamount, goodsTotalCurrencyfraction } = order
  const usualItems = items.filter((item) => item.usualItemId)
  const usualItemsIds = usualItems.reduce((acc, item) => ({ ...acc, [item.id]: item.id }), {})
  const { toggleFavorite, isFavorite } = useToggleFavorite(usualItemsIds)
  const orderItems = items.filter((item) => item !== null).slice(0, 2)
  const remainingItems = items.length - orderItems.length

  const usualItemHashmap = useMemo(() =>
    getUsualItemHashmap(items, storeId)
    , [items, storeId])

  const { handleOnClickOrderAgain: orderAgain, loading } = useOrderAgain(
    order.orderId,
    usualItemHashmap
  )

  const handleItemClick = (item: OrderMyJoeItem) => {
    onClickUsualItemMark(item, storeId)
    toggleFavorite(item.id)
  }

  const totalOrderAmount = useMemo(
    () =>
      formatMoney(
        Big(goodsTotalCurrencyamount)
          .div(goodsTotalCurrencyfraction)
          .toNumber()
      ),
    [goodsTotalCurrencyamount, goodsTotalCurrencyfraction]
  )

  return (
    <RecentOrder>
      <IssuedAt css={type === 'full' ? { textAlign: 'center' } : {}}>
        {formatDate(new Date(ts), 'EEEE MMM dd, hh:mm a')}
      </IssuedAt>
      <ItemsList>
        {(type === 'full' ? items : orderItems).map((item, index) => (
          <OrderItem
            key={`recent-order-${item.id}-${index}`}
            item={item}
            handleItemClick={handleItemClick}
            isFavorite={isFavorite(item.id)}
            storeId={storeId}
            orderId={order.orderId}
          />
        ))}
      </ItemsList>
      <Divider />
      <Action onClick={() => orderAgain()}>
        {
          type === 'full' ?
            <span>Total: {totalOrderAmount}</span>
            :
            (remainingItems > 0 && <span>+{remainingItems} items</span>)
        }
        <Button disabled={loading}>
          <Cart size={16} /> <span>Add {items.length} to cart</span>
        </Button>
      </Action>
    </RecentOrder>
  )
}
