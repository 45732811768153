import { Element } from 'react-scroll'
import { CSS } from '@stitches/react'
import { styled } from 'styles/stitches.config'
import * as Widget from 'components/Widget'
import { H3 } from 'design-system/headings'

export const ScrollElement = styled(Element, {
  width: '100%',
})

export const widgetCSS: CSS = {
  margin: 0,

  [`& ${Widget.Main}`]: {
    backgroundColor: 'transparent',
    padding: '0 $xxs',
  },

  [`& ${Widget.Border}`]: {
    paddingLeft: 0,
  },
}

export const Item = styled('div', {
  alignItems: 'flex-start',
  color: '$grey1000',
  cursor: 'pointer',
  display: 'flex',
  padding: '$m $s',
  gap: '$xs',
})

export const ItemInfo = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxxs',
})

export const ItemName = styled('div', {
  fontSize: '$body1',
  fontWeight: '$bold',
  lineHeight: '$body1',
})

export const ItemPrice = styled('div', {
  fontSize: '$body2',
  fontWeight: '$bold',
  lineHeight: '$body2',
})

export const ItemDescription = styled('div', {
  color: '$grey800',
  fontSize: '$body3',
  lineHeight: '$body3',
})

export const ItemImage = styled('div', {
  borderRadius: '$xs',
  flex: '0 0 auto',
  overflow: 'hidden',
  width: 92,
  height: 92,
})

export const Tag = styled('span', {
  padding: '$xxxs $xs',
  fontSize: '$tagLabel',
  borderRadius: '$xxxs',
  width: 'fit-content',
})

export const NewTag = styled(Tag, {
  color: '$blue600',
  background: '$blue100',
})

export const PopularTag = styled(Tag, {
  color: '$green800',
  background: '$green100',
})

export const Subcategory = styled('div', {
  padding: '$m 0',

  [`& ${Item}:not(:last-child)`]: {
    borderBottom: '1px solid $grey200',
  },
})

export const Title = styled(H3, {
  backgroundColor: '$grey200',
  borderRadius: 15,
  color: '$grey1000',
  marginBottom: '$xxs',
  padding: '0 $s',
})
