import { Carousel, CarouselOptions } from 'components/Carousel'
import GenericContentWidget from 'components/GenericContentWidget'
import { useLoyaltyData } from 'hooks/useLoyaltyData'
import { isEmpty } from 'lodash'
import Router from 'next/router'
import { ItemCard } from './components'
import { StoreUpsellItem } from 'types/api/store'
import { useEffect } from 'react'

type Props = {
  storeId: string
  onFetchFeaturedItems?: (data: StoreUpsellItem[]) => void
}

export const FeaturedItems = ({ storeId, onFetchFeaturedItems }: Props) => {
  const { loyaltyData: { featuredItems, isLoadingFeaturedItems: isLoading } } = useLoyaltyData('storeFeatured', storeId)

  useEffect(() => {
      onFetchFeaturedItems?.(featuredItems)
  }, [featuredItems, onFetchFeaturedItems])

  if (!storeId || (isEmpty(featuredItems) && !isLoading)) return null

  const carouselOptions: CarouselOptions = {
    align: 'prev',
    pagination: true,
    panelsPerView: 1,
  } as unknown as CarouselOptions

  const handleViewMore = () => {
    Router.push({
      pathname: 'explore/stores/[storeId]/featured-items',
      query: {
        storeId
      }
    })
  }

  return (
    <GenericContentWidget isLoading={isLoading} title="Featured" action={handleViewMore}>
      <Carousel options={carouselOptions} customCss={{ zIndex: 0, position: 'relative' }}>
        {featuredItems?.map((item) => (
          <ItemCard key={item.name} item={item} storeId={storeId} css={{ paddingRight: '$m' }} />
        ))}
      </Carousel>
    </GenericContentWidget>
  )
}
