import { chain, concat, includes, isEmpty, map } from 'lodash'
import { useMemo } from 'react'
import { StoreMenuItem as MenuItem } from 'types/api'
import StoreMenuItem from './StoreMenuItem'
import {
  StoreMenuFeaturedItemsProps,
  StoreMenuSubcategoryProps,
} from './StoreMenu.types'
import { Subcategory, Title } from './StoreMenu.styles'

export const StoreMenuFeaturedItems = ({
  category,
  featuredItemIds,
  popularItems,
  storeId,
}: StoreMenuFeaturedItemsProps) => {
  const featuredItems = useMemo(() => {
    const items = concat(...map(category.subCategories, 'items'))

    return chain(items)
      .filter((item) => includes(featuredItemIds, item.itemId))
      .map(addItemStatus(popularItems))
      .value()
  }, [category, featuredItemIds, popularItems])

  if (isEmpty(featuredItems)) {
    return null
  }

  return (
    <Subcategory>
      <Title>Featured Items</Title>
      {featuredItems.map((item) => (
        <StoreMenuItem key={item.itemId} item={item} storeId={storeId} />
      ))}
    </Subcategory>
  )
}

export const StoreMenuSubcategory = ({
  subcategory,
  popularItems,
  storeId,
}: StoreMenuSubcategoryProps) => {
  const items = useMemo(() => {
    return chain(subcategory.items).map(addItemStatus(popularItems)).value()
  }, [subcategory, popularItems])

  return (
    <Subcategory>
      <Title>{subcategory.name}</Title>
      {items.map((item) => (
        <StoreMenuItem key={item.itemId} item={item} storeId={storeId} />
      ))}
    </Subcategory>
  )
}

const addItemStatus = (popularItems: string[]) => (item: MenuItem) => {
  let status

  if (item.latest) {
    status = 'new'
  }

  if (includes(popularItems, item.itemId)) {
    status = 'popular'
  }

  return {
    ...item,
    status,
  }
}
